<template>
  <div class="mr-echarts" :id="id"></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "MrEcharts",
  props: {
    id: {
      type: String,
      default: () => {
        return Date.now() + Math.random().toString(36).substr(2)
      }
    },
    options: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      chart: null
    }
  },
  mounted() {
    this.initChart();
  },
  methods: {
    initChart() {
      const chartDom = document.getElementById(this.id);
      const myChart = echarts.init(chartDom);
      this.chart = myChart;
    },
    initResize() {
      window.addEventListener("resize", () => {
        this.chart.resize();
      })
    },
    deposeCharts() {
      this.chart.dispose()
    }
  },
  beforeDestroy() {
    this.deposeCharts();
  },
  watch: {
    options: {
      deep: true,
      handler() {
        this.chart && this.chart.setOption(this.options);
      }
    }
  }
}
</script>

<style scoped lang="scss">
.mr-echarts {
  width: 100%;
  height: 100%;
}
</style>